<template>
  <div class="userbox">
    <div class="center">
      <div class="l_box point">
        <div
          class="item"
          v-for="(item, index) in titlelist"
          :key="index"
          @click="changetab(item)"
          :class="tabindex == item.id ? 'acitem' : ''"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="r_box">
        <userInfo v-if="this.tabindex == 1" />
        <depot v-else-if="this.tabindex == 2" />
        <purses v-else-if="this.tabindex == 3" />
        <sensing v-else-if="this.tabindex == 5" />
        <orderList v-else-if="this.tabindex == 6" />
      </div>
    </div>
    <!-- 退出确认 -->
    <div class="changeview" v-show="logout">
      <div class="namebox">
        <div class="title">
          Confirmation of exit
          <img
            class="point"
            src="../../assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="closelogout"
          />
        </div>
        <div class="flex-c">
          <div class="logouttext">Confirmation of logging out</div>
        </div>
        <div class="flex-c">
          <div class="amend2 point" @click="closelogout">cancel</div>
          <div class="amend2 point" @click="logoutclick">logout</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import {
  SetprofileApi,
  UploadApi,
  rechargeApi,
  LogoutApi,
} from "../../network/api";
import userInfo from "./userInfo.vue";
import depot from "./depot.vue";
import purses from "./purses.vue";
import sensing from "./sensing.vue";
import orderList from "./orderList.vue";
export default {
  components: {
    userInfo,
    depot,
    purses,
    sensing,
    orderList,
    // point,
    // mbdepot,
  },
  data() {
    return {
      titlelist: [
        {
          title: "My Profile",
          id: 1,
        },
         {
          title: "Order Record",
          id: 6,
        },
        {
          title: "My warehouse",
          id: 2,
        },
        {
          title: "Wallet records",
          id: 3,
        },
        {
          title: "Key Check",
          id: 5,
        },
       
        {
          title: "logout",
          id: 7,
        },
      ],
      tabindex: 1,
      // 手机端标题列表
      // mbtitlelist: [
      //   {
      //     title: "My warehouse",
      //     img: require("../../assets/images/pc/mb-user-icon1.png"),
      //     id: 2,
      //   },
      //   {
      //     title: "Wallet records",
      //     img: require("../../assets/images/pc/mb-user-icon2.png"),
      //     id: 3,
      //   },
      //   // {
      //   //   title: "Integral Record",
      //   //   img: require('../../assets/images/pc/mb-user-icon3.png'),
      //   //   id: 4,
      //   // },
      //   {
      //     title: "Key Check",
      //     img: require("../../assets/images/pc/mb-user-icon4.png"),
      //     id: 5,
      //   },
      //   {
      //     title: "Order Record",
      //     img: require("../../assets/images/pc/mb-user-icon5.png"),
      //     id: 6,
      //   },
      //   {
      //     title: "logout",
      //     img: require("../../assets/images/pc/mb-user-icon6.png"),
      //     id: 7,
      //   },
      // ],
      typeindex: null,
      //个人信息的
      codevalu: "",
      amend: false, //修改蒙层
      name: "",
      nameshow: false, //修改昵称
      headshow: false, //修改头像
      // headlist: [
      //   {
      //     img: "/uploads/20231204/fe1e0ed1101c6c5801e7e1316dd03632.png",
      //     id: 1,
      //   },
      //   {
      //     img: "/uploads/20231204/0e99a2eedb74eb7ff46f1165be634d77.png",
      //     id: 2,
      //   },
      // ],
      headindex: 1,
      imageUrl: "",
      changeshow: false,
      amount: 0,
      loading: false,
      logout: false,
    };
  },
  created() {
    if (this.$route.query.TabIndex) {
      this.tabindex = this.$route.query.TabIndex;
    } else {
      this.tabindex = 1;
    }
  },
  computed: {
    ...mapState(["isMinDevice", "userInfo", "onlineUrl"]),
    ...mapGetters(["isVip"]),
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollIntoView(".userbox");
    }, 0);
  },
  methods: {
    closelogout() {
      this.logout = false;
      this.$router.push({
        path: "/User",
        query: {
          TabIndex: 1,
        },
      });
    },
    async logoutclick() {
      const res = await LogoutApi();
      localStorage.removeItem("isShowTip");
      localStorage.removeItem("userInfo");
      this.clearToken();
      window.location.replace("/Home");
      setTimeout(() => {
        location.reload();
      }, 300);
    },
    //获取用户信息

    // 手机端切换
    // async typeclick(v) {
    //   this.typeindex = v.id;
    //   this.tabindex = v.id;
    //   this.$router.push({
    //     path: "/User",
    //     query: {
    //       TabIndex: this.typeindex,
    //     },
    //   });
    //   if (v.id == "7") {
    //     const res = await LogoutApi();
    //     localStorage.removeItem("isShowTip");
    //     localStorage.removeItem("userInfo");
    //     this.clearToken();
    //     window.location.replace("/Home");
    //   }
    // },
    async changetab(v) {
      this.tabindex = v.id;
      this.$router.push({
        path: "/User",
        query: {
          TabIndex: this.tabindex,
        },
      });
      if (v.id == "7") {
        this.logout = true;
      }
    },
    ...mapMutations(["setUserInfo"]),
    ...mapActions(["ReFreshUserInfo"]),
    async rechargeOnClick() {
      try {
        this.loading = true;
        const params = {
          amount: this.amount,
        };
        const res = await rechargeApi(params);
        if (res.code === 1 && res.data?.url) {
          window.open(res.data?.url, "_blank");
          this.amount = 0;
          this.changeshow = false;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // async handleAvatarSuccess(e) {
    //   const params = new FormData();
    //   const file = e.target.files[0];
    //   params.append("file", file);
    //   const res = await UploadApi(params);
    //   this.imageUrl = res.data.url;
    // },
    // 修改昵称弹窗
    changename() {
      this.amend = true;
      this.nameshow = true;
      this.name = this.userInfo.nickname;
      this.imageUrl = this.userInfo.avatar;
    },
    // 确认修改昵称
    entename() {
      if (this.name != "") {
        SetprofileApi(this.name, this.imageUrl).then((res) => {
          if (res.code == 1) {
            this.amend = false;
            this.nameshow = false;
            this.ReFreshUserInfo();
          }
          (r) => {
            if (r.code != 1) {
              this.$message.error(r.msg);
            }
          };
        });
      }
    },
    //修改头像弹窗
    changehead() {
      this.imageUrl = this.userInfo.avatar;
      this.amend = true;
      this.headshow = true;
    },
    tabhead(v) {
      this.headindex = v.id;
      this.imageUrl = v.img;
    },

    entehead() {
      SetprofileApi(this.name, this.imageUrl).then((res) => {
        if (res.code == 1) {
          this.amend = false;
          this.nameshow = false;
          this.ReFreshUserInfo();
          this.imageUrl = "";
        }
        (r) => {
          if (r.code != 1) {
            this.$message.error(r.msg);
          }
        };
      });
    },
    //充值
    rechangeshow() {
      this.changeshow = true;
    },

    ...mapMutations(["clearToken"]),
  },
  watch: {
    $route: {
      handler(newVal) {
        this.tabindex = newVal.query.TabIndex;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.userbox {
  width: 100%;
  min-height: 75vh;
  overflow: hidden;
  padding-bottom: 1.5rem;

  .center {
    width: 14.6rem;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l_box {
      width: 2.83rem;
      height: 7.54rem;
      .item {
        width: 100%;
        height: 0.5rem;
        text-align: left;
        line-height: 0.5rem;
        color: #595959;
        font-size: 0.18rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        border: 0.01rem solid #eaeaea;
        padding: 0 0.2rem;
        box-sizing: border-box;
      }

      .acitem {
        background: #ffb100;
        color: #ffff;
      }
    }

    .r_box {
      width: 11.53rem;
      height: 7.54rem;
      background: rgb(255, 255, 255);
      // box-shadow: 0.04rem 0.04rem 0.04rem 0.04rem rgba(0, 0, 0, 0.1);
      // border-radius: 0.06rem;
    }
  }
  .changeview {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 1999;

    .namebox {
      width: 8.5rem;
      height: 3.17rem;
      border-radius: 0.12rem;
      background: #fff;

      .title {
        width: 100%;
        height: 0.8rem;
        background: #ffb100;
        text-align: center;
        line-height: 0.8rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        border-radius: 0.12rem 0.12rem 0 0;
        position: relative;

        img {
          display: block;
          width: 0.35rem;
          height: 0.35rem;
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .logouttext {
        width: 80%;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .amend2 {
        width: 2.5rem;
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
