export const tableData = {
  list: [
    {
      orderid: 1,
      ordername: "商城购买",
      ordernum: 10,
      ordertime: Date.now(),
      ordersprice: 222,
    },
    {
      orderid: 2,
      ordername: "商城购买",
      ordernum: 10,
      ordertime: Date.now(),
      ordersprice: 222,
    },
   
  ],
};
