<template>
  <div>
    <div class="infobox-view">
      <div class="userhead">
        <img
          :src="onlineUrl + userInfo.avatar"
          alt=""
          class="point"
          @click="changehead"
        />
      </div>
      <div class="userlist">
        <div class="flex-c" style="position: relative">
          <p>Name:</p>
          <span class="listbg">{{ userInfo.nickname }}</span>
          <div class="amend point" @click="changename">Amend</div>
        </div>
        <div class="flex-c">
          <p>Email:</p>
          <span class="listbg">{{ userInfo.email }}</span>
        </div>
      </div>
    </div>
    <div class="codebox">
      <div class="balance">
        <p class="box">Balance:${{ userInfo.money }}</p>
      </div>
      <div class="codetitle">Deposit method</div>
      <div class="paytab">
        <div
          class="payicon point"
          v-for="item in payicon"
          :key="item.id"
          @click="payclick(item)"
        >
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
    <div class="changeview" v-if="amend">
      <div class="namebox" v-show="nameshow">
        <div class="title">
          Changing Nickname
          <img
            class="point"
            src="../../assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="
              () => {
                (this.amend = false), (this.nameshow = false);
              }
            "
          />
        </div>
        <div class="flex-c">
          <input class="putbox" type="text" v-model="name" />
        </div>
        <div class="flex-c">
          <div class="amend2 point" @click="entename">Amend</div>
        </div>
      </div>
      <div class="headbox" v-show="headshow">
        <div class="title">
          Changing Head Portrait
          <img
            class="point"
            src="@/assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="
              () => {
                (this.amend = false), (this.headshow = false);
              }
            "
          />
        </div>
        <div class="flex-c">
          <div class="curhead">
            <img :src="onlineUrl + imageUrl" alt="" />
            <div class="curtitle">Current avatar</div>
          </div>
          <div class="silod"></div>
          <div class="clickhead point">
            <div
              class="item"
              style="position: relative"
              v-for="(item, index) in headlist"
              :key="index"
              @click="tabhead(item)"
              :class="headindex == item.id ? 'acitem' : ''"
            >
              <img :src="onlineUrl + item.img" alt="" />
            </div>
            <div class="item point">
              <img src="../../assets/images/pc/pc-user-addhead.png" alt="" />
              <input
                class="point"
                type="file"
                ref="uploader"
                @change="handleAvatarSuccess"
                id="upload"
                style="
                  style=width: 1.2rem;
                  height: 1.2rem;
                  opacity: 0;
                  position: absolute;
                  left: 0;
                  top: 0;
                "
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div class="flex-c">
          <div class="amend2 point" @click="entehead">Amend</div>
        </div>
      </div>
    </div>
    <div class="changeview" v-if="changeshow">
      <div
        v-loading="loading"
        element-loading-text="Order is being created..."
        class="box"
      >
        <!-- 确认充值 -->
        <div class="title">
          Recharge
          <img
            class="point"
            src="@/assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="
              () => {
                this.changeshow = false;
              }
            "
          />
        </div>
        <div class="main">
          <div class="rechangebox">
            <div style="padding-left: 0.3rem" class="online-title">
              Online recharge(Only supports virtual currency)
            </div>
            <div
              style="padding: 0 0.3rem; margin-bottom: 0.3rem"
              class="flex-s"
            >
              <div class="input-box" style="margin-right: 0.2rem">
                <span>USDT</span>
                <el-input-number
                  class="input-num"
                  placeholder="Please enter the amount"
                  :controls="false"
                  v-model="amount"
                  :min="0"
                  :max="99999"
                  label="input"
                ></el-input-number>
              </div>
              <div @click="rechargeOnClick" class="btn point">recharge</div>
            </div>

            <p>
              If you need other methods of payment, or if you have any
              questions, please contact customer service
            </p>
            <a
              class="tobox t1"
              href="https://join.skype.com/invite/bBDIX4DdFUQK"
              target="_blank"
              ><img
                src="@/assets/images/pc/pc-user-rechange-icon1.png"
                alt=""
              />
              <span>ACE recharge service(24-hour service)</span>
            </a>
            <a class="tobox t2" href="https://t.me/ACESOFTKEYS" target="_blank"
              ><img
                src="@/assets/images/pc/pc-user-rechange-icon2.png"
                alt=""
              />
              <span>@ACE_keys</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { SetprofileApi, UploadApi, rechargeApi } from "../../network/api";
export default {
  data() {
    return {
      codevalu: "",
      amend: false, //修改蒙层
      name: "",
      nameshow: false, //修改昵称
      headshow: false, //修改头像
      headlist: [
        {
          img: "/uploads/20231204/fe1e0ed1101c6c5801e7e1316dd03632.png",
          id: 1,
        },
        {
          img: "/uploads/20231204/0e99a2eedb74eb7ff46f1165be634d77.png",
          id: 2,
        },
      ],
      headindex: 1,
      imageUrl: "",
      changeshow: false,
      amount: 0,
      loading: false,

      payicon: [
        {
          id: 1,
          icon: require("@/assets/images/pc/payicon3.png"),
        },
        {
          id: 2,
          icon: require("../../assets/images/pc/payicon2.png"),
        },
        {
          id: 3,
          icon: require("../../assets/images/pc/payicon1.png"),
        },
      ],
    };
  },

  computed: {
    ...mapState(["userInfo", "onlineUrl"]),
    ...mapGetters(["isVip"]),
  },
  methods: {
    payclick(v) {
      switch (v.id) {
        case 1:
          this.rechangeshow();
          break;
        case 2:
          this.$message.warning("Under maintenance");
          break;
        case 3:
          this.$message.warning("Under maintenance");
          break;
      }
    },
    ...mapMutations(["setUserInfo"]),
    ...mapActions(["ReFreshUserInfo"]),
    async rechargeOnClick() {
      try {
        this.loading = true;
        const params = {
          amount: this.amount,
        };
        const res = await rechargeApi(params);
        if (res.code === 1 && res.data?.url) {
          window.open(res.data?.url, "_blank");
          this.amount = 0;
          this.changeshow = false;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 绑定邀请人
    async bindcode() {
      if (this.codevalu != "") {
        const res = await setInviterApi(this.codevalu);
        if (res.code == 1) {
          this.ReFreshUserInfo();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    async handleAvatarSuccess(e) {
      const params = new FormData();
      const file = e.target.files[0];
      params.append("file", file);
      const res = await UploadApi(params);
      this.imageUrl = res.data.url;
    },
    // 修改昵称弹窗
    changename() {
      this.amend = true;
      this.nameshow = true;
      this.name = this.userInfo.nickname;
    },
    // 确认修改昵称
    entename() {
      if (this.name != "") {
        SetprofileApi(this.name, this.userInfo.avatar).then((res) => {
          if (res.code == 1) {
            this.amend = false;
            this.nameshow = false;
            this.ReFreshUserInfo();
          }
          (r) => {
            if (r.code != 1) {
              this.$message.error(r.msg);
            }
          };
        });
      }
    },
    //修改头像弹窗
    changehead() {
      this.imageUrl = this.userInfo.avatar;
      this.amend = true;
      this.headshow = true;
    },
    tabhead(v) {
      this.headindex = v.id;
      this.imageUrl = v.img;
    },

    entehead() {
      SetprofileApi(this.name, this.imageUrl).then((res) => {
        if (res.code == 1) {
          this.amend = false;
          this.nameshow = false;
          this.ReFreshUserInfo();
          this.imageUrl = "";
        }
        (r) => {
          if (r.code != 1) {
            this.$message.error(r.msg);
          }
        };
      });
    },
    //充值
    rechangeshow() {
      this.changeshow = true;
    },
  },
};
</script>

<style lang="scss">
.infobox-view {
  width: 10.23rem;
  height: 1.5rem;
  margin: 0 auto 0.59rem;
  display: flex;
  align-items: center;

  .userhead {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    border-radius: 50%;
    margin-right: 0.8rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .isvipbox {
      width: 1.03rem;
      height: 0.26rem;
      position: absolute;
      bottom: -0.05rem;
      right: 18%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 0%;
      }
    }

    .amend {
      width: 1.03rem;
      height: 0.26rem;
      border-radius: 0.02rem;
      background: #0076ff;
      position: absolute;
      bottom: 0;
      right: 16%;
      font-size: 0.18rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.26rem;
      text-align: center;
    }
  }

  .userlist {
    width: 2.71rem;

    .listbg {
      width: 2.05rem;
      height: 0.26rem;
      background: #f5f5f5;
      text-align: center;
      line-height: 0.26rem;
    }

    .amend {
      width: 1.03rem;
      height: 0.26rem;
      border-radius: 0.02rem;
      background: #ffb100;
      position: absolute;
      right: -1.47rem;
      font-size: 0.18rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffff;
      line-height: 0.26rem;
      text-align: center;
    }
    p {
      color: #797979;
    }
  }
}

.codebox {
  width: 10.23rem;
  padding-bottom: 0.2rem;
  margin: 0 auto;

  .balance {
    width: 100%;
    height: 0.65rem;
    display: flex;
    align-items: center;

    .box {
      width: 1.6rem;
      height: 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      margin: 0 0.4rem;
      font-size: 0.25rem;
      font-weight: 600;
    }
  }

  .codetitle {
    margin-left: 0.4rem;
    height: 0.34rem;
    font-size: 0.24rem;
    font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 600;
    color: #000000;
    line-height: 0.28rem;
  }

  .paytab {
    width: 9.53rem;
    height: 0.5rem;
    margin: 0.4rem auto;
    display: flex;
    align-items: center;
    .payicon {
      width: 1.8rem;
      height: 0.5rem;
      margin-right: 0.2rem;
      img {
        width: 100%;
      }
    }
  }
}

.codeputbox {
  width: 9.53rem;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .codeput {
    width: 7.94rem;
    height: 0.5rem;
    border: 0;
    text-decoration: none;
    font-size: 0.2rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b5b5b5;
    padding-left: 0.2rem;
    box-sizing: border-box;

    &::placeholder {
      font-size: 0.2rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #b5b5b5;
    }
  }

  .save {
    width: 1.29rem;
    height: 0.5rem;
    background: #0076ff;
    font-size: 0.24rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.5rem;
    text-align: center;
  }
}

.rechange {
  font-size: 0.24rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.35rem;
  cursor: pointer;
  background: url("../../assets/images/pc/pc-user-rechangebg.png") no-repeat;
  background-size: 100% 100%;
}

.changeview {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.2);
  z-index: 1999;

  .title {
    width: 100%;
    height: 0.8rem;
    background: #ffb100;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.28rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    border-radius: 0.12rem 0.12rem 0 0;
    position: relative;

    img {
      display: block;
      width: 0.35rem;
      height: 0.35rem;
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
  }

  .amend2 {
    width: 3.18rem;
    height: 0.6rem;
    border-radius: 0.04rem;
    background: #ffb100;
    font-size: 0.2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.6rem;
    text-align: center;
  }

  .namebox {
    width: 8.5rem;
    height: 3.17rem;
    border-radius: 0.12rem;
    background: #fff;

    .putbox {
      width: 6.9rem;
      height: 0.64rem;
      margin: 0.2rem auto;
      border: 0.01rem solid #b7b7b7;
      text-decoration: none;
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #848484;
    }
  }

  .headbox {
    width: 8.5rem;
    height: 3.17rem;
    border-radius: 0.12rem;
    background: #fff;

    .curhead {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      box-sizing: border-box;
      background: #ffb100;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .curtitle {
        position: absolute;
        bottom: -0.05rem;
        width: 1.12rem;
        height: 0.2rem;
        background: #ffb100;
        color: #fff;
        text-align: center;
        line-height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
      }
    }

    .silod {
      width: 0rem;
      height: 1.2rem;
      border: 0.01rem solid #e4e8ec;
      margin: 0 0.6rem;
    }

    .clickhead {
      width: 4rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .acitem {
        border: 0.02rem solid #0076ff;
        border-radius: 50%;
      }
    }
  }

  .box {
    width: 8.5rem;

    .title {
      width: 100%;
      height: 0.8rem;
      background: #ffb100;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.12rem 0.12rem 0 0;
      position: relative;

      img {
        display: block;
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
      }
    }

    .main {
      width: 100%;
      background: #fff;
      border-radius: 0 0 0.12rem 0.12rem;
    }

    .rechangebox {
      width: 5.1rem;
      margin: 0 auto;
      overflow: hidden;
      .online-title {
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
        font-size: 0.18rem;
        font-weight: bold;
      }
      .input-box {
        width: 100%;
        display: flex;
        align-items: center;
        span {
          font-weight: 800;
          margin-right: 0.2rem;
        }
        .input-num {
          width: 100%;
          border: 1px solid #dcdfe6;
          border-radius: 0.03rem;
          box-sizing: border-box;
          line-height: unset;
          height: 0.32rem;
          flex-grow: 1;

          .el-input {
            width: 100%;
            .el-input__inner {
              border: none;
              text-align: left;
              width: 100%;
              font-size: 0.18rem;
            }
            .el-input__inner::placeholder {
              font-size: 0.18rem;
            }
          }
        }
      }
      .btn {
        border: 1px solid #269ad2;
        background: #269ad2;
        padding: 0rem 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 0.03rem;
        height: 0.32rem;
        box-sizing: border-box;
      }

      .tobox {
        display: flex;
        width: 4.6rem;
        height: 0.4rem;
        margin: 0.2rem auto;
        align-items: center;
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        box-sizing: border-box;
        padding-left: 0.16rem;

        span {
          margin-left: 0.2rem;
        }
      }

      .t1 {
        background: #3773b8;
      }

      .t2 {
        background: #269ad2;
      }

      P {
        text-align: center;
      }
    }
  }
}
</style>
