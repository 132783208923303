<template>
  <div class="recordbox">
    <!-- 钱包记录 -->
    <div class="serchbox">
      <div>
        <Cselect
          :selectList="selectList"
          v-model="selectItem"
          @input="dotype"
        />
      </div>
    </div>
    <div class="orderlist">
      <div class="common-table">
        <div class="left-common flex-s table-header">
          <div style="flex-grow: 1">Order number</div>
          <div>Time</div>
          <div>Type</div>
          <div>Change</div>
          <div>After</div>
          <div>Status</div>
          <!-- <div style="width: 0.16rem"></div> -->
        </div>
        <div class="table-body" style="height: 5.8rem">
          <div
            v-for="item in moneylist"
            :key="item.id"
            class="left-common flex-s"
            style="height: 0.5rem"
          >
            <div
              style="
                flex-grow: 1;
                text-align: center;
                box-sizing: border-box;
                padding-left: 0.2rem;
                font-size: 0.14rem;
              "
            >
              {{ item.order_sn }}
            </div>
            <div
              style="
                text-align: center;
                padding-left: 0.45rem;
                box-sizing: border-box;
              "
            >
              {{ $formatTime(item.createtime * 1000, "dd-MM-yyyy hh:mm:ss") }}
            </div>
            <div style="width: 17%; text-align: center">
              <p v-if="item.type == 1">recharge</p>
              <p v-if="item.type == 4">refunded</p>
              <p v-if="item.type == 3">consume</p>
              <p v-if="item.type == 2">Welfare</p>
            </div>
            <div v-show="item.type != 3">${{ item.money }}</div>
            <div v-show="item.type == 3">
              -${{ Math.abs(item.money).toFixed(2) }}
            </div>
            <div>${{ item.after }}</div>
            <div style="color: rgb(58, 194, 112)" v-if="item.status == 'paid'">
              successful
            </div>
            <div
              style="color: rgb(58, 194, 112)"
              v-else-if="item.status == 'paid_over'"
            >
              successful
            </div>
            <div style="color: #dfdfdf" v-else-if="item.status == 'cancel'">
              cancel
            </div>
            <div
              class="point"
              style="color: #00000; text-decoration: underline"
              v-else-if="
                item.status === 'check' && item.expired_at * 1000 > Date.now()
              "
              @click="topay(item.url)"
            >
              unpaid
            </div>
            <div
              class="point"
              style="color: #f6841c; text-decoration: underline"
              v-else-if="
                item.status == 'wrong_amount' &&
                item.expired_at * 1000 > Date.now()
              "
              @click="topay(item.url)"
            >
              Supplementary payment
            </div>
            <div
              style="color: rgb(58, 194, 112)"
              v-else-if="item.expired_at === null"
            >
              successful
            </div>
            <p style="color: rgb(58, 194, 112)" v-else-if="item.money < 0">
              successful
            </p>
            <div
              style="color: red"
              v-else-if="item.expired_at * 1000 < Date.now()"
            >
              expired
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-pagination">
      <el-pagination
        :current-page.sync="page"
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { tableData } from "./index.js";
import { getMoneyApi } from "../../network/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tableData,
      selectItem: "All",
      page: 1, //页码
      pageSize: 8, //当前页面
      total: 0, //总条数

      type: "", //当前type
      moneylist: [], //钱包记录
    };
  },
  created() {
    this.getdata(this.page);
  },
  computed: {
    selectList() {
      return ["All", "recharge", "consume ", "Refund", " Welfare"];
    },
    ...mapState(["isMinDevice"]),
  },
  methods: {
    // 需要支付跳转链接
    topay(v) {
      window.open(v);
    },
    dotype(v) {
      if (v == "recharge") {
        this.type = 1;
        this.getdata(this.page);
      } else if (v == "consume ") {
        this.type = 3;
        this.getdata(this.page);
      } else if (v == " Welfare") {
        this.type = 2;
        this.getdata(this.page);
      } else if (v == "Refund") {
        this.type = 4;
        this.getdata(this.page);
      } else {
        this.type = "";
        this.getdata(this.page);
      }
    },
    async getdata(page) {
      const res = await getMoneyApi(page, this.type);
      this.moneylist = res.data.Items;
      this.pageSize = res.data.ItemsPerPage;
      this.total = res.data.TotalItems;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getdata(val, this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.recordbox {
  width: 10.93rem;
  height: 6.8rem;
  // margin: 0.3rem auto;
  .serchbox {
    // width: 10.21rem;
    height: 0.6rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mb_serchbox {
    width: 17.152rem;
    height: 1.2rem;
    margin: 0 0 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffff;
  }

  .orderlist {
    width: 100%;
    // background: #f6faff;
    .common-table {
      // padding: 0 0.3rem;
      box-sizing: border-box;
    }
  }

  .common-pagination {
    width: 10.93rem;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
}
.mb_pursesbox {
  width: 17.152rem;
  height: 24.5rem;
  margin: 0.3rem auto;
  padding-bottom: 0.5rem;
}
</style>
