var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"infobox-view"},[_c('div',{staticClass:"userhead"},[_c('img',{staticClass:"point",attrs:{"src":_vm.onlineUrl + _vm.userInfo.avatar,"alt":""},on:{"click":_vm.changehead}})]),_c('div',{staticClass:"userlist"},[_c('div',{staticClass:"flex-c",staticStyle:{"position":"relative"}},[_c('p',[_vm._v("Name:")]),_c('span',{staticClass:"listbg"},[_vm._v(_vm._s(_vm.userInfo.nickname))]),_c('div',{staticClass:"amend point",on:{"click":_vm.changename}},[_vm._v("Amend")])]),_c('div',{staticClass:"flex-c"},[_c('p',[_vm._v("Email:")]),_c('span',{staticClass:"listbg"},[_vm._v(_vm._s(_vm.userInfo.email))])])])]),_c('div',{staticClass:"codebox"},[_c('div',{staticClass:"balance"},[_c('p',{staticClass:"box"},[_vm._v("Balance:$"+_vm._s(_vm.userInfo.money))])]),_c('div',{staticClass:"codetitle"},[_vm._v("Deposit method")]),_c('div',{staticClass:"paytab"},_vm._l((_vm.payicon),function(item){return _c('div',{key:item.id,staticClass:"payicon point",on:{"click":function($event){return _vm.payclick(item)}}},[_c('img',{attrs:{"src":item.icon,"alt":""}})])}),0)]),(_vm.amend)?_c('div',{staticClass:"changeview"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nameshow),expression:"nameshow"}],staticClass:"namebox"},[_c('div',{staticClass:"title"},[_vm._v(" Changing Nickname "),_c('img',{staticClass:"point",attrs:{"src":require("../../assets/images/pc/pc-home-icon10.png"),"alt":""},on:{"click":() => {
              (this.amend = false), (this.nameshow = false);
            }}})]),_c('div',{staticClass:"flex-c"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"putbox",attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"amend2 point",on:{"click":_vm.entename}},[_vm._v("Amend")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.headshow),expression:"headshow"}],staticClass:"headbox"},[_c('div',{staticClass:"title"},[_vm._v(" Changing Head Portrait "),_c('img',{staticClass:"point",attrs:{"src":require("@/assets/images/pc/pc-home-icon10.png"),"alt":""},on:{"click":() => {
              (this.amend = false), (this.headshow = false);
            }}})]),_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"curhead"},[_c('img',{attrs:{"src":_vm.onlineUrl + _vm.imageUrl,"alt":""}}),_c('div',{staticClass:"curtitle"},[_vm._v("Current avatar")])]),_c('div',{staticClass:"silod"}),_c('div',{staticClass:"clickhead point"},[_vm._l((_vm.headlist),function(item,index){return _c('div',{key:index,staticClass:"item",class:_vm.headindex == item.id ? 'acitem' : '',staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.tabhead(item)}}},[_c('img',{attrs:{"src":_vm.onlineUrl + item.img,"alt":""}})])}),_c('div',{staticClass:"item point"},[_c('img',{attrs:{"src":require("../../assets/images/pc/pc-user-addhead.png"),"alt":""}}),_c('input',{ref:"uploader",staticClass:"point",staticStyle:{"style=width":"1.2rem","height":"1.2rem","opacity":"0","position":"absolute","left":"0","top":"0"},attrs:{"type":"file","id":"upload","accept":"image/*"},on:{"change":_vm.handleAvatarSuccess}})])],2)]),_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"amend2 point",on:{"click":_vm.entehead}},[_vm._v("Amend")])])])]):_vm._e(),(_vm.changeshow)?_c('div',{staticClass:"changeview"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box",attrs:{"element-loading-text":"Order is being created..."}},[_c('div',{staticClass:"title"},[_vm._v(" Recharge "),_c('img',{staticClass:"point",attrs:{"src":require("@/assets/images/pc/pc-home-icon10.png"),"alt":""},on:{"click":() => {
              this.changeshow = false;
            }}})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"rechangebox"},[_c('div',{staticClass:"online-title",staticStyle:{"padding-left":"0.3rem"}},[_vm._v(" Online recharge(Only supports virtual currency) ")]),_c('div',{staticClass:"flex-s",staticStyle:{"padding":"0 0.3rem","margin-bottom":"0.3rem"}},[_c('div',{staticClass:"input-box",staticStyle:{"margin-right":"0.2rem"}},[_c('span',[_vm._v("USDT")]),_c('el-input-number',{staticClass:"input-num",attrs:{"placeholder":"Please enter the amount","controls":false,"min":0,"max":99999,"label":"input"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('div',{staticClass:"btn point",on:{"click":_vm.rechargeOnClick}},[_vm._v("recharge")])]),_c('p',[_vm._v(" If you need other methods of payment, or if you have any questions, please contact customer service ")]),_vm._m(0),_vm._m(1)])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"tobox t1",attrs:{"href":"https://join.skype.com/invite/bBDIX4DdFUQK","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/pc/pc-user-rechange-icon1.png"),"alt":""}}),_c('span',[_vm._v("ACE recharge service(24-hour service)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"tobox t2",attrs:{"href":"https://t.me/ACESOFTKEYS","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/pc/pc-user-rechange-icon2.png"),"alt":""}}),_c('span',[_vm._v("@ACE_keys")])])
}]

export { render, staticRenderFns }